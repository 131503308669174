import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import styles from "../components/ARWebCheckoutCartItem.module.css";

import { ReactComponent as UpIcon } from "../assets/images/up_arrow_grey.svg";
import { ReactComponent as DownIcon } from "../assets/images/down_arrow_grey.svg";

import i18next from "../constants/i18n";
import { TABLE } from "../constants";
import { getOrdersWithTotalPrice } from "../constants/commonFunc";
import CartItemsWithModifiers from "../modules/past-orders/components/CartItemsWithModifiers";

let fontFamilyStyle = {
	temp1: {},
	temp2: {
		fontFamily: "GilroySemiBold !important",
	},
};

const ARWebCheckoutCartItem = (props) => {
	const [expand, setExpand] = useState(true);

	let { cartItems, page, restTemplate, menuList, tableNumber, orderType } =
		props;

	const cartList = useMemo(() => {
		return getOrdersWithTotalPrice(cartItems, menuList)?.cartItems ?? [];
	}, [cartItems, menuList]);

	return (
		<Box
			className={styles.mainView}
			style={
				page == "checkout" && restTemplate == "temp1"
					? {
							boxShadow: "0px 40px 70px -21px #72640E24",
							border: "1px solid #ffe227",
							borderRadius: "17px",
							backgroundColor: "transparent",
					  }
					: {
							border: "0px",
							borderRadius: "15px",
							backgroundColor: "#FFF",
							boxShadow: '0px 1px 3px 0px #00000040'
					  }
			}
			mx={1}
			mt={page == "checkout" ? 8 : 2}
			mb={page == "checkout" ? 2 : 0.5}
			px={2}
			py={2}
		>
			{page == "checkout" ? (
				<>
					<Box
						className={styles.blurImg}
						sx={restTemplate == "temp1" ? null : { backgroundColor: "#FFF" }}
					/>
					{restTemplate == "temp1" ? (
						<Box
							onClick={() => setExpand(!expand)}
							className={styles.expandIcon}
							sx={{
								cursor: { xl: "pointer" },
								path: {
									stroke: "#000",
								},
							}}
						>
							{expand ? (
								<UpIcon width={20} height={20} />
							) : (
								<DownIcon width={20} height={20} />
							)}
						</Box>
					) : null}
				</>
			) : null}
			<Box className={styles.orderTitleMainView}>
				<Box className={styles.orderTitleView}>
					<Typography
						className={styles.orderTitleText}
						sx={{ ...fontFamilyStyle[restTemplate] }}
					>
						{i18next.t("Your Order")}
					</Typography>
					{orderType === TABLE && tableNumber ? (
						<Box
							sx={{
								background: "#BD9968",
								height: "65px",
								width: "65px",
								borderRadius: "50%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								mr: 2,
								mt: 1,
							}}
						>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontSize: "20px !important",
									color: "#fff !important",
									fontWeight: 600,
								}}
							>
								{tableNumber}
							</Typography>
						</Box>
					) : null}
					{props.orderId ? (
						<Typography className={styles.orderNoText}>
							{i18next.t("Order no")} : {props.orderId.toString().substr(-5)}
						</Typography>
					) : null}
				</Box>
			</Box>
			{expand ? (
				<Box className={styles.cartItemListView} mt={2} mb={1}>
					<CartItemsWithModifiers cartList={cartList} />
				</Box>
			) : null}
		</Box>
	);
};

export default ARWebCheckoutCartItem;
